import React from 'react'
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom'

import Home from './screens/home/home'
import Guests from './screens/guests'

const router = createBrowserRouter([{
  path: '/',
  element: <Home />
}, {
  path: '/guests',
  element: <Guests />
}])

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
