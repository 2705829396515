import NavBar from '../home/components/nav-bar'
import Table from './components/table'

const Guests = () => {
  return (
    <main className='font-hoeflerText font-regular flex flex-col items-center text-content text-sm sm:text-lg md:text-xl'>
      <NavBar />

      <Table />
    </main>
  )
}

export default Guests
