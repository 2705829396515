import React, { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

export default function NavBar() {
  return (
    <Popover className='relative bg-white w-full mb-12'>
      <div className='pointer-events-none absolute inset-0 z-30 shadow' aria-hidden='true' />

      <div className='relative z-20'>
        <div className='mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 md:space-x-10 lg:px-8 w-full'>
          <div className='font-semibold text-[#EFBED5] uppercase'>
            <Link to='/'>
              J&A
            </Link>
          </div>

          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#EFBED5]">
              <span className="sr-only">Ouvrir le menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <div className="hidden md:flex">
            <div className="flex items-center md:ml-12">
              <button className='bg-[#EFBED5] px-4 py-2 rounded text-white hover:scale-105 transition-all active:scale-95 text-xs uppercase' role='link'>
                <Link to={{ pathname: '/', hash: 'bookRoom' }}>
                  Je confirme ma présence
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6 sm:pb-8">
              <div className="flex items-center justify-between">
                <Popover.Button className='bg-[#EFBED5] px-4 py-2 rounded text-white hover:scale-105 transition-all active:scale-95 text-xs uppercase' role='link'>
                  <Link to={{ pathname: '/', hash: 'bookRoom' }}>
                    Je confirme ma présence
                  </Link>
                </Popover.Button>

                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#EFBED5]">
                    <span className="sr-only">Fermer le menu</span>

                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

