import React from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper"

import Map from '../../../components/map'
import Marker from '../../../components/marker'
import { mapStyles } from '../../../constants/map'

const render = (status: Status) => {
  return <h1 className="font-bold text-black">{status}</h1>;
}

const HomeMap = () => {
  const center = { lat: 44.97361453860573, lng: -0.6059257941295152 }
  const zoom = 9;

  return (
    <section className='h-96 mt-px relative w-screen'>
      <Wrapper apiKey='AIzaSyAJvC8SRoPrrvRmmxtL6TLB5zaY0MRWw68' render={render}>
        <Map styles={mapStyles} center={center} zoom={zoom}>
          <Marker position={{ lat: 44.8379082, lng: -0.5796762 }} title='Hôtel de ville de Bordeaux' />
          <Marker position={{ lat: 45.18842245693056, lng: -0.6157649621915989 }} title="Château Pontet d'Eyrans" />
        </Map>
      </Wrapper>
    </section>
  )
}

export default HomeMap
