import { useEffect, useState } from 'react'

const Marker = (options: google.maps.MarkerOptions) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      const newMarker = new google.maps.Marker()

      const infowindow = new google.maps.InfoWindow({ content: `<div class="flex flex-col"><h3>${options.title}</h3><button class="underline" type="button"><a href="https://www.google.fr/maps/dir/${options.position?.lat},${options.position?.lng}">Aller vers ce lieu</a></button></div>` });

      newMarker.addListener("click", () => infowindow.open(options.map, newMarker));

      setMarker(newMarker);
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, options.map, options.position?.lat, options.position?.lng, options.title]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default Marker
