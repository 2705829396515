import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import NavBar from './components/nav-bar'
import HomeMap from './components/home-map'

const Home = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1))

      if (elem) {
        setTimeout(() => elem.scrollIntoView({ behavior: 'smooth' }), 200)
      }
    }
  }, [location.hash])

  return (
    <main className='font-hoeflerText font-regular flex flex-col items-center text-content text-sm sm:text-lg md:text-xl'>
      <NavBar />

      <section className='items-center flex flex-col mx-4 sm:mx-6 md:mx-10 lg:mx-16 xl:mx-24'>
        <picture>
          <source
            srcSet="/save-the-date.webp, /save-the-date@full.webp 3000w"
            type="image/webp" />

          <img
            alt='Save the date'
            className='drop-shadow-md pr-[3%] w-[900px]'
            src='/save-the-date.png'
            srcSet='/save-the-date.png, /save-the-date@full.png 3000w'
          />
        </picture>
      </section>

      <section className='items-center flex flex-col px-6 md:px-10 lg:px-16 xl:px-24 pb-2 pt-10 lg:pt-16 xl:pt-24'>
        <p className='px-8 sm:px-4 text-center max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
          Nous avons le plaisir de vous inviter à célébrer notre union.
          Le mariage est pour nous synonyme de fête et de partage.
          Afin de créer les meilleurs souvenirs ensemble, nous avons décidé de célébrer notre amour sur 3 jours, du vendredi 1er au dimanche 3 septembre 2023.
        </p>

        <picture>
          <source
            srcSet="/pink-ribbon.webp"
            type="image/webp" />

          <img alt='Nœud rose' className='pt-2 w-16 sm:w-24 md:w-32' src='/pink-ribbon.jpg' />
        </picture>
      </section>


      <section className='bg-yellowBackground items-center flex flex-col p-6 md:p-10 lg:p-16 xl:p-24 w-full'>
        <h5 className='font-arielScript text-title text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl'>Theme</h5>

        <p className='px-8 sm:px-4 text-center max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
          "Vintage Romance". Union de nos deux personnalités, ce thème allie l'ancien et le moderne, l'élégance et la folie.
          Couleurs pastels, rubans satins, costumes romantiques et manches bouffantes. Tout pour créer l'ambiance de nos rêves.
          Comme une image vaut mille mots, nous avons fait une sélection de photos afin que vous puissiez vous en inspirer.
          Vos plus belles tenues romantiques sont attendues !
        </p>

        <div className='bg-white drop-shadow-md p-2 md:p-4 lg:p-6 my-8 md:my-12 lg:my-16 xl:my-20 -rotate-[6deg] rounded'>
          <picture>
            <source
              srcSet="/moodboard.webp"
              type="image/webp" />

            <img
              alt='Images illustrant le thème'
              className='rounded-sm w-[min(calc(100vw-148px),900px)]'
              src='/moodboard.jpg'
            />
          </picture>
        </div>

        <p className='px-8 sm:px-4 sm:py-4 md:py-6 lg:py-8 xl:py-10 text-center max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
          Ces touches de couleur sont les bienvenues sur vos habits.
        </p>

        <div className='bg-white drop-shadow-sm flex items-center flex-col p-2 md:p-4 lg:p-6 my-8 md:my-12 lg:my-16 xl:my-20 rounded'>
          <div className='flex flex-col relative items-center'>
            <picture>
              <source
                srcSet="/color-code.webp"
                type="image/webp" />

              <img alt='Code couleur' className='drop-shadow-sm w-[min(calc(100vw-148px),800px)]' src='/color-code.png' />
            </picture>

            <h5 className='absolute font-arielScript text-title text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl -top-7 sm:-top-10 md:-top-12 lg:-top-14 xl:-top-16'>
              Code couleur
            </h5>
          </div>
        </div>
      </section>

      <section className='items-center flex flex-col p-6 md:p-10 lg:p-16 w-full' id='program'>
        <h5 className='font-arielScript text-title text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl'>Programme</h5>

        <div className='mt-6 text-left max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
          <ul className='list-disc list-outside'>
            <li className='my-3'>Vendredi 1er septembre 2023 : Jour du mariage</li>

            <ul className='list-outside list-[circle]'>
              <li className='ml-4 my-1.5'>10H25 : Arrivée à la mairie</li>
              <li className='ml-4 my-1.5'>10H40 : Cérémonie civile à la mairie</li>
              <li className='ml-4 my-1.5'>11H : Fin de la cérémonie civile, photos dans les jardins de la mairie</li>
              <li className='ml-4 my-1.5'>11H30 : En route, direction le château</li>
              <li className='ml-4 my-1.5'>12H30 : Arrivée au château, check-in pour les chambres, un repas est prévu directement par le château, les préparatifs des mariés commencent</li>
              <li className='ml-4 my-1.5'>16H : Cérémonie laïque au château</li>
              <li className='ml-4 my-1.5'>17H : Photos de couple et de groupe</li>
              <li className='ml-4 my-1.5'>17H30 : Ouverture du vin d'honneur</li>
              <li className='ml-4 my-1.5'>18H : Buffet du vin d'honneur</li>
              <li className='ml-4 my-1.5'>20H : Entrée dans la salle + fiesta</li>
            </ul>

            <li className='my-3'>Samedi 2 septembre 2023 : Journée "chill" au château (amenez votre maillot !)</li>

            <ul className='list-outside list-[circle]'>
              <li className='ml-4 my-1.5'>11H : Brunch</li>
              <li className='ml-4 my-1.5'>20H : Rougail saucisses "C'est Frédo"</li>
            </ul>

            <li className='my-3'>Dimanche 3 septembre 2023 : La fin du week-end</li>

            <ul className='list-outside list-[circle]'>
              <li className='ml-4 my-1.5'>9H: Petit-déjeuner</li>
              <li className='ml-4 my-1.5'>Départ du château dans la matinée</li>
            </ul>
          </ul>
        </div>
      </section>

      <div className='bg-yellowBackground flex flex-col items-center m-0 w-full'>
        <section className='items-center flex flex-col p-6 md:p-10 lg:p-16 w-full' id='bookRoom'>
          <h5 className='font-arielScript text-title text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl'>Adresses</h5>

          <p className='pt-4 sm:pt-2 px-8 sm:px-4 text-center max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
            Pour le confort de tous nous avons choisi un lieu sur lequel vous pourrez loger et passer le week-end en toute sérénité.
            Si vous voulez rester plus longtemps sur le lieu, avant le vendredi (plutôt si vous ne venez pas à la mairie le matin car le lieu n'est pas à Bordeaux) ou après le dimanche matin,
            vous pouvez louer une chambre pour plus longtemps à un tarif préférentiel
            <a href='https://www.latourperrier.fr/'> en contactant directement <span className='underline'>le château</span></a>
            {' '}au <a className='underline' href='tel:+33 5 57 33 17 68'>+33 5 57 33 17 68</a>
          </p>

          <p className='pt-8 sm:pt-4 px-8 sm:px-4 text-center max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
            N.B : Il y a eu un changement côté mairie, le mariage devait initialement avoir lieu à la mairie de Caudéran mais aura finalement lieu à l'hôtel de ville de Bordeaux.
          </p>
        </section>

        <button className='bg-[#EFBED5] mb-6 px-6 py-3 rounded text-white hover:scale-105 transition-all active:scale-95 uppercase' role='link'>
          <a href='https://lydia-app.com/collect/mariage-justine-axel/fr'>
            Je confirme ma présence
          </a>
        </button>

        <section className='items-center flex flex-col p-6 md:p-10 lg:p-16 xl:p-24 pt-2 md:pt-4 lg:pt-6 xl:pt-8 w-full'>
          <p className='px-8 sm:px-4 text-center max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl'>
            <div className='italic'><span className='font-bold not-italic'>Mairie : </span>Pl. Pey Berland, 33000 Bordeaux</div>

            <div className='italic'><span className='font-bold not-italic'>Lieu de réception : </span>Château Pontet d'Eyrans, 53 Route de Royan, 33390 Eyrans</div>
          </p>
        </section>
      </div>

      <HomeMap />

      <section className='items-center flex flex-col p-6 md:p-10 lg:p-16 w-full'>
        <p className='px-8 sm:px-4 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl text-center'>
          Hâte de partager ce merveilleux moment en votre compagnie.

          <div>
            Sincèrement,
          </div>

          <div className='font-arielScript mt-4 text-2xl'>
            Axel et Justine
          </div>
        </p>
      </section>
    </main>
  )
}

export default Home
