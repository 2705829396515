import { Fragment } from 'react'

interface Person {
  allergies?: string[]
  firstName: string
  lastName: string
  isComing: boolean
  isHidden?: boolean
  isPaid?: boolean
  willPay?: boolean
  noBed?: boolean
  babyBed?: boolean
  isChild?: boolean
  isVeg?: boolean
  poke: string
  rs: boolean
}

const JustineFamily: Person[] = [
  { firstName: 'Justine', lastName: 'Desire', isComing: true, isPaid: false, isVeg: true, poke: 'végé', rs: true },
  { firstName: 'Lisa', lastName: 'Desire', isComing: true, isPaid: false, poke: 'saumon', rs: true },
  { firstName: 'Thelma', lastName: 'Desire', isComing: true, isPaid: false, poke: 'végé', rs: true },
  { firstName: 'Nathalie', lastName: 'Desire', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Frédéric', lastName: 'Desire', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Andrée', lastName: 'Duby', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Alain', lastName: 'Duby', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Mimose', lastName: 'Desire', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Sylvie', lastName: 'Truccolo', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Nora', lastName: 'Truccolo', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Marie', lastName: 'Truccolo', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Brigitte', lastName: 'Hoareau', isComing: true, isPaid: true, poke: 'saumon', rs: true },
  { firstName: 'Hugues', lastName: 'Hoareau', isComing: true, isPaid: true, poke: 'poulet', rs: true },
  { firstName: 'Fanny', lastName: 'Hoareau', isComing: true, isPaid: true, poke: 'saumon', rs: true },
  { firstName: 'Nikki', lastName: 'King', isComing: true, isPaid: true, poke: 'poulet', rs: true },
  { firstName: 'Margaux', lastName: 'King Hoareau', isComing: true, isPaid: true, isChild: true, poke: 'saumon', rs: true },
  { firstName: 'Rudy', lastName: 'Hoareau', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Élodie', lastName: 'Verdan', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Pierre-Emmanuel', lastName: 'Hoareau', isComing: true, isPaid: true, poke: 'saumon', rs: true },
  { firstName: 'Hannah', lastName: 'Hoareau', isComing: true, isPaid: true, poke: 'saumon', rs: true },
  { firstName: 'Sophie', lastName: 'Hoareau', isComing: true, isPaid: true, isChild: true, poke: 'poulet', rs: true },
  { firstName: 'Sophie', lastName: 'Moutalou', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Liana', lastName: 'Moutalou', isComing: false, isChild: true, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Benjamin', lastName: 'Bonmalais', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Justine', lastName: 'Guichard', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Matthieu', lastName: 'Hoareau', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Jocelyne', lastName: 'Xavier', isComing: true, isPaid: false, isVeg: true, poke: 'poulet', rs: true },
  { firstName: 'Serge', lastName: 'Godefroy', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Lorraine', lastName: 'Godefroy', isComing: true, isVeg: true, poke: 'végé', rs: false },
  { firstName: 'Dominique', lastName: 'Hoyez', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Jean-Robert', lastName: 'Dehaies', isComing: true, allergies: ['fromage', 'crème'], poke: 'poulet (sans vinaigrette)', rs: true },
  { firstName: 'Sophie', lastName: 'Gireaud', isComing: false, isHidden: true, poke: 'poulet', rs: true }
]

const AxelFamily: Person[] = [
  { firstName: 'Axel', lastName: 'Vencatareddy', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Emmanuelle', lastName: 'Vencatareddy', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Gilbert', lastName: 'Vencatareddy', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Eliot', lastName: 'Vencatareddy', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Elaïa', lastName: 'Vencatareddy', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Yanaë', lastName: 'Vencatareddy', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Marie-Geneviève', lastName: 'Hoareau', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Marie-France', lastName: 'Hoareau', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Marie-Dominique', lastName: 'Maillot', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Camille', lastName: 'Maillot', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Samuel', lastName: 'Maillot', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Adélaïde', lastName: 'Maillot', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Enora', lastName: 'Maillot', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Marie-Odile', lastName: 'Fontaine', isComing: true, isPaid: true, isVeg: true, poke: 'saumon', rs: false },
  { firstName: 'John', lastName: 'Fontaine', isComing: true, isPaid: true, isVeg: true, poke: 'végé', rs: false },
  { firstName: 'Julie', lastName: 'Fontaine', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Pierre', lastName: 'Yvernel', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Rose', lastName: 'Yvernel', isComing: true, babyBed: true, isChild: true, poke: '---', rs: true },
  { firstName: 'Thomas', lastName: 'Fontaine', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Laura', lastName: 'Fontaine', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Morane', lastName: 'Fontaine', isComing: true, isVeg: true, poke: 'végé', rs: false },
  { firstName: 'Jean-François', lastName: 'Hoareau', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Isabelle', lastName: 'Hoareau', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Gaëtan', lastName: 'Hoareau', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Mahé', lastName: 'Hoareau', isComing: true, noBed: true, isChild: true, poke: 'poulet', rs: true },
  { firstName: 'Marie-Pierre', lastName: 'Hoareau', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Olivier', lastName: 'Hoarau', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Salomé', lastName: 'Trape', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Adrien', lastName: 'Trape', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Perrine', lastName: 'Quineau', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Sébastien', lastName: 'Hoareau', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Gaëlle', lastName: 'Mani', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Elise', lastName: 'Deboisvilliers', isComing: false, isHidden: true, isChild: true, poke: 'poulet', rs: true },
  { firstName: 'Stéphane', lastName: 'Trape', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Céline', lastName: 'Trape', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Patrice', lastName: 'Vencatareddy', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Kathleen', lastName: 'Coutet', isComing: true, isPaid: true, poke: 'saumon', rs: true },
  { firstName: 'Robert', lastName: 'Coutet', isComing: true, isPaid: true, poke: 'saumon', rs: true },
  { firstName: 'Mathis', lastName: 'Coutet', isComing: true, isPaid: true, isChild: true, poke: '---', rs: true },
  { firstName: 'Annaelle', lastName: '(acc. Mathis)', isComing: true, isPaid: true, poke: 'poulet', rs: true },
]

const Friends: Person[] = [
  { firstName: 'Camille', lastName: 'Mazet', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Xavier', lastName: 'Selly', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Mélio', lastName: 'Selly-Mazet', isComing: false, isHidden: true, isChild: true, poke: 'poulet', rs: true },
  { firstName: 'Azumi', lastName: 'Duc', isComing: true, willPay: false, poke: 'saumon', rs: true },
  { firstName: 'Antoine', lastName: 'Gaoua', isComing: true, willPay: false, poke: 'saumon', rs: true },
  { firstName: 'Mathilde', lastName: 'Traversié', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Solène', lastName: 'Morin', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Bénédicte', lastName: 'Dufour', isComing: true, willPay: false, poke: 'saumon', rs: true },
  { firstName: 'Lucas', lastName: 'Chollet', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Camille', lastName: 'Deflesselle', isComing: true, isVeg: true, poke: 'végé', rs: false },
  { firstName: 'Louis', lastName: 'Vezia', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Sotheary', lastName: 'Ung', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Rémi', lastName: 'Ysvelain', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Amélie', lastName: 'Ysvelain', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Ivy', lastName: 'Ysvelain', isComing: false, isHidden: true, isChild: true, poke: 'poulet', rs: true },
  { firstName: 'Ayden', lastName: 'Ysvelain', isComing: false, isHidden: true, isChild: true, poke: 'poulet', rs: true },
  { firstName: 'Talal', lastName: 'Mohammad', isComing: true, isVeg: true, allergies: ['Uniquement halal (sinon végé)'], poke: 'saumon', rs: false },
  { firstName: 'Nicolas', lastName: 'Constanty', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Loick', lastName: 'Lin-Ken-Hsien', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Valentin', lastName: 'Galian', isComing: true, poke: 'saumon', rs: true },
  { firstName: 'Florent', lastName: 'Aubras', isComing: false, isHidden: true, poke: 'poulet', rs: true },
  { firstName: 'Gallien', lastName: 'Wong', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Arnaud', lastName: 'Thomazo', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Edo', lastName: 'Setiawan', isComing: true, poke: 'poulet', rs: false },
  { firstName: 'Céline', lastName: 'Couderc', isComing: true, poke: 'poulet', rs: true },
  { firstName: 'Kévin', lastName: 'Tran', isComing: true, isPaid: false, poke: 'saumon', rs: true },
  { firstName: 'Marion', lastName: 'Lamour', isComing: true, isPaid: false, isVeg: true, poke: 'saumon', rs: false },
]

const tableNames = ['Palerme', '', 'La Saline', 'Le Guillaume', 'Bellemène', 'Caudéran', 'Paris', 'Maurice']

console.log(tableNames)

const sortFunction = (a: Person, b: Person) => {
  if (a.isComing && !b.isComing) {
    return -1
  } else if (!a.isComing && b.isComing) {
    return 1
  }

  if (a.lastName && !b.lastName) {
    return -1
  } else if (!a.lastName && b.lastName) {
    return 1
  } else if (a.lastName.localeCompare(b.lastName) !== 0) {
    return a.lastName.localeCompare(b.lastName)
  }

  return a.firstName.localeCompare(b.firstName)
}

const categories = [{
  name: 'Famille de Justine',
  people: JustineFamily.sort(sortFunction)
}, {
  name: 'Famille d\'Axel',
  people: AxelFamily.sort(sortFunction)
}, {
  name: 'Nos amis',
  people: Friends.sort(sortFunction)
}]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function Table() {
  return (
    <table className="-mt-12 min-w-full border-separate" style={{ borderSpacing: 0 }}>
      <thead className="bg-gray-50">
        <tr>
          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
          >
            Nom
          </th>

          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
          >
            Allergies
          </th>

          <th
            scope="col"
            className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
          >
            Poké
          </th>
        </tr>
      </thead>

      <tbody className="bg-white">
      {categories.map((category) => (
        <Fragment key={category.name}>
          <tr className="border-t border-gray-200">
            <th
              colSpan={5}
              scope="colgroup"
              className="bg-gray-200 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
            >
              {category.name}
            </th>
          </tr>

          {category.people.filter(({ isHidden }) => !isHidden).map((person, personIdx) => (
            <tr className={person.isComing ? 'bg-gray-50' : 'relative'} key={`${person.firstName}-${person.lastName}`}>
              <td
                className={classNames(
                  personIdx !== category.people.length - 1 ? 'border-b border-gray-200' : '',
                  'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 lg:pl-8',
                  person.isVeg ? 'text-lime-400' : 'text-gray-900',
                  person.isChild ? 'text-rose-400' : 'text-gray-900'
                )}
              >
                {`${person.firstName} ${person.lastName}`}
              </td>

              <td
                className={classNames(
                  personIdx !== category.people.length - 1 ? 'border-b border-gray-200' : '',
                  'whitespace-nowrap px-3 py-4 text-sm font-medium sm:pl-6 lg:pl-8 text-red-700'
                )}
              >
                {(person.allergies ?? []).join(', ')}
              </td>

              <td
                className={classNames(
                  personIdx !== category.people.length - 1 ? 'border-b border-gray-200' : '',
                  'whitespace-nowrap px-3 py-4 text-sm font-medium sm:pl-6 lg:pl-8',
                  person.poke.includes('poulet') ? 'text-yellow-700' : person.poke.includes('saumon') ? 'text-red-300' : 'text-lime-400'
                )}
              >
                {person.poke}
              </td>
            </tr>
          ))}

          <tr className="border-t border-gray-200">
            <td
              colSpan={5}
              className="bg-gray-50 justify-evenly px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
            >
              <div className='flex flex-col sm:flex-row items-center justify-evenly'>
                <div>
                  Total : {category.people.filter(({ isHidden }) => !isHidden).length}
                </div>

                <div>
                  Confirmés : {category.people.filter(({ isHidden, isComing }) => !isHidden && isComing).length} (Dont enfants : {category.people.filter(({ isHidden, isComing, isChild }) => !isHidden && isComing && isChild).length})
                </div>

                <div>
                  En attente : {category.people.filter(({ isHidden, isComing }) => !isHidden && !isComing).length}
                </div>
              </div>
            </td>
          </tr>
        </Fragment>
      ))}

        <tr className="border-t border-gray-200">
          <td
            colSpan={5}
            className="bg-gray-50 justify-evenly px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
          >
            <div className='flex flex-col sm:flex-row items-center justify-evenly'>
              <div>
                Poké végé : {AxelFamily.filter(({ isHidden, isComing, poke }) => !isHidden && isComing && poke.includes('végé')).length + JustineFamily.filter(({ isComing, isHidden, poke }) => !isHidden && isComing && poke.includes('végé')).length + Friends.filter(({ isComing, isHidden, poke }) => !isHidden && isComing && poke.includes('végé')).length}
              </div>

              <div>
                Poké poulet : {AxelFamily.filter(({ isHidden, isComing, poke }) => !isHidden && isComing && poke.includes('poulet')).length + JustineFamily.filter(({ isComing, isHidden, poke }) => !isHidden && isComing && poke.includes('poulet')).length + Friends.filter(({ isComing, isHidden, poke }) => !isHidden && isComing && poke.includes('poulet')).length}
              </div>

              <div>
                Poké saumon : {AxelFamily.filter(({ isHidden, isComing, poke }) => !isHidden && isComing && poke.includes('saumon')).length + JustineFamily.filter(({ isComing, isHidden, poke }) => !isHidden && isComing && poke.includes('saumon')).length + Friends.filter(({ isComing, isHidden, poke }) => !isHidden && isComing && poke.includes('saumon')).length}
              </div>
            </div>
          </td>
        </tr>

        <tr className="border-t border-gray-200">
          <td
            colSpan={5}
            className="bg-gray-50 justify-evenly px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
          >
            <div className='flex flex-col sm:flex-row items-center justify-evenly'>
              <div>
                Repas végé : {AxelFamily.filter(({ isComing, isChild, isHidden, isVeg }) => !isHidden && isComing && !isChild && isVeg).length + JustineFamily.filter(({ isChild, isComing, isHidden, isVeg }) => !isHidden && isComing && !isChild && isVeg).length + Friends.filter(({ isChild, isComing, isHidden, isVeg }) => !isHidden && isComing && !isChild && isVeg).length}
              </div>

              <div>
                Repas bœuf : {AxelFamily.filter(({ isComing, isChild, isHidden, isVeg }) => !isHidden && isComing && !isChild && !isVeg).length + JustineFamily.filter(({ isChild, isComing, isHidden, isVeg }) => !isHidden && isComing && !isChild && !isVeg).length + Friends.filter(({ isChild, isComing, isHidden, isVeg }) => !isHidden && isComing && !isChild && !isVeg).length}
              </div>

              <div>
                Repas enfant : {AxelFamily.filter(({ isComing, isChild, isHidden }) => !isHidden && isComing && isChild).length + JustineFamily.filter(({ isChild, isComing, isHidden }) => !isHidden && isComing && isChild).length + Friends.filter(({ isChild, isComing, isHidden }) => !isHidden && isComing && isChild).length}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
